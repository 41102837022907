<template>
  <div>
    <l-map
      class="VueLeafMap"
      :zoom="zoom"
      :center="center"
      :options="{ zoomControl: false }"
      :bounds="bounds"
    >
      <l-tile-layer
        :url="url"
        :attribution="attribution"
      />
      <l-control-zoom :position="zoomControlPosition" />
      <l-control-fullscreen
        :position="fullScreenControlPosition"
        :options="{ title: { false: 'Full Screen', true: 'Dashboard View' } }"
      />
      <l-control
        v-if="tripPlayBack && routeCoordinates.length > 0"
        :position="playBackControlPosition"
      >
        <div class="d-flex gap-05">
          <v-btn
            v-if="!isPlaying"
            color="primary"
            small
            @click="setIntervalTime"
          >
            {{ intervalTimeOptions.indexOf(intervalTime) + 1 }}x
          </v-btn>
          <v-btn
            color="primary"
            small
            @click="isPlaying ? tripPause() : tripPlay()"
          >
            <v-icon v-if="isPlaying">
              mdi-pause
            </v-icon>
            <v-icon v-else>
              mdi-play
            </v-icon>
          </v-btn>
          <v-btn
            v-if="isPlaying"
            color="danger"
            small
            @click="tripStop"
          >
            <v-icon>mdi-stop</v-icon>
          </v-btn>
        </div>
      </l-control>

      <l-control :position="contentPosition">
        <slot name="content" />
      </l-control>

      <!--  -->
      <!--  -->
      <!--  -->
      <!--  -->
      <!-- <l-popup>
              <div class="">
                Latitude:
                <span>{{ popupLatLng.lat?.toFixed(4) }}</span>
              </div>
              <div class="">
                Longitude:
                <span>{{ popupLatLng.lng?.toFixed(4) }}</span>
              </div>
              <div class="">
                Speed:
                <span>{{ popupLatLng?.position?.speed }} km/h</span>
              </div>
              <div
                v-if="vehicleTech === 'ev'"
                class=""
              >
                SOC:
                <span>{{ popupLatLng.state_of_charge }}</span>
              </div>
              <div
                v-if="vehicleTech === 'ice'"
                class=""
              >
                Fuel Level:
                <span>{{ popupLatLng.fuel1 }}</span>
              </div>
              <div class="">
                Date Time:
                <span>{{ popupLatLng.date_time }}</span>
              </div>
            </l-popup> -->
      <!-- @click="showPopupMarkers" -->
      <!-- <template v-for="(job, i) in routeCoordinates">
        <template v-for="(point, j) in job">
          <l-marker
            :key="`${i}-${j}`"
            :lat-lng="point"
          />
        </template>
      </template> -->

      <template v-for="(mrk, i) in jobMarkers">
        <l-marker
          :key="`${i}`"
          :lat-lng="mrk"
        >
          <l-icon>
            <v-icon
              v-if="i === 0"
              color="#000"
              x-large
            >
              mdi-google-maps
            </v-icon>
            <icons
              v-else-if="i == jobMarkers.length - 1"
              name="marker-end"
            />
            <icons
              v-else
              :name="`marker-${i}`"
            />
          </l-icon>
          <!-- <l-icon v-else>
            <icons :name="`marker-${i}`" />
          </l-icon> -->
          <!-- <l-icon v-else-if="i == jobMarkers.length - 1">
            <Icons name="marker-end" />
          </l-icon> -->
          <!-- <l-icon v-else>
            <icons :name="`marker-${i}`" />
          </l-icon> -->
          <!-- <icons
              v-if="i == 1"
              name="marker-1"
            />
            <icons
              v-if="i == 2"
              name="marker-2"
            />
            <icons
              v-if="i == 3"
              name="marker-3"
            />
            <icons
              v-if="i == 4"
              name="marker-4"
            />
            <icons
              v-if="i == 5"
              name="marker-5"
            />
            <icons
              v-if="i == 6"
              name="marker-6"
            />
            <icons
              v-if="i == 7"
              name="marker-7"
            />
            <icons
              v-if="i == 8"
              name="marker-8"
            />
            <icons
              v-if="i == 9"
              name="marker-9"
            />
            <icons
              v-if="i == 10"
              name="marker-10"
            />
            <icons
              v-if="i == 11"
              name="marker-11"
            />
            <icons
              v-if="i == 12"
              name="marker-12"
            />
            <icons
              v-if="i == 13"
              name="marker-13"
            />
            <icons
              v-if="i == 14"
              name="marker-14"
            />
            <icons
              v-if="i == 15"
              name="marker-15"
            />
            <icons
              v-if="i == 16"
              name="marker-16"
            />
            <icons
              v-if="i == 17"
              name="marker-17"
            />
            <icons
              v-if="i == 18"
              name="marker-18"
            />
            <icons
              v-if="i == 19"
              name="marker-19"
            />
            <icons
              v-if="i == 20"
              name="marker-20"
            />
            <icons
              v-if="i == 21"
              name="marker-21"
            />
            <icons
              v-if="i == 22"
              name="marker-22"
            />
            <icons
              v-if="i == 23"
              name="marker-23"
            />
            <icons
              v-if="i == 24"
              name="marker-24"
            />
            <icons
              v-if="i == 25"
              name="marker-25"
            />
            <icons
              v-if="i == 26"
              name="marker-26"
            />
            <icons
              v-if="i == 27"
              name="marker-27"
            />
            <icons
              v-if="i == 28"
              name="marker-28"
            />
            <icons
              v-if="i == 29"
              name="marker-29"
            />
            <icons
              v-if="i == 30"
              name="marker-30"
            />
            <icons
              v-if="i == 31"
              name="marker-31"
            />
            <icons
              v-if="i == 32"
              name="marker-32"
            />
            <icons
              v-if="i == 33"
              name="marker-33"
            />
            <icons
              v-if="i == 34"
              name="marker-34"
            />
            <icons
              v-if="i == 35"
              name="marker-35"
            />
            -->
        </l-marker>
      </template>

      <!-- Define the LPopup component within the LMap component -->
      <!-- Job Route -->
      <l-polyline
        :lat-lngs="routeCoordinates"
        :options="getPolylineOptions('job')"
        @click="showPopupPolyline"
      >
        <l-popup>
          <div class="">
            Latitude:
            <span>{{ popupLatLng.lat?.toFixed(4) }}</span>
          </div>
          <div class="">
            Longitude:
            <span>{{ popupLatLng.lng?.toFixed(4) }}</span>
          </div>
        </l-popup>
      </l-polyline>
      <!-- Job Route -->
      <!-- Follow Route -->
      <l-polyline
        :lat-lngs="followRoute"
        :options="getPolylineOptions('follow')"
      />
      <!-- Follow Route -->
    </l-map>
  </div>
</template>

<script>
import { latLngBounds } from "leaflet";
import LControlFullscreen from "vue2-leaflet-fullscreen";
import "leaflet-fullscreen/dist/leaflet.fullscreen.css";
import {
  LMap,
  LTileLayer,
  LControl,
  LControlZoom,
  LMarker,
  LIcon,
  LPolyline,
  LPopup,
} from "vue2-leaflet";

export default {
  components: {
    LMap,
    LTileLayer,
    LControl,
    LControlZoom,
    LMarker,
    LIcon,
    LPolyline,
    LPopup,
    LControlFullscreen,
    icons: () => import("@/components/base/icons.vue"),
  },
  props: {
    zoomControlPosition: {
      type: String,
      default() {
        return "bottomright";
      },
    },
    fullScreenControlPosition: {
      type: String,
      default() {
        return "bottomright";
      },
    },
    playBackControlPosition: {
      type: String,
      default() {
        return "topright";
      },
    },
    contentPosition: {
      type: String,
      default() {
        return "topleft";
      },
    },
    tripsData: {
      type: Array,
      default() {
        return [];
      },
    },
    followData: {
      type: Array,
      default() {
        return [];
      },
    },
    vehicleTech: {
      type: String,
      default() {
        return "";
      },
    },
    tripPlayBack: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  data() {
    return {
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png?lang=en",
      attribution:
        '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      zoom: 16,
      center: [24.8822916, 67.067249], // Bahadurabad Lat lng when No Data
      // routeCoordinates: [],

      // For trip Play back
      isPlaying: false,
      intervalId: null,
      intervalTime: 1000,
      intervalTimeOptions: [1000, 800, 600, 400, 200],
      count: 1,
      //
      popupLatLng: [],
      //
    };
  },
  computed: {
    routeCoordinates() {
      const arr = [];
      if (this.tripsData.length > 0) {
        const data = [...this.tripsData];
        data.forEach((el) => {
          arr.push(el.route);
        });
      }
      return arr;
    },
    jobMarkers() {
      const arr = [];
      if (this.tripsData.length > 0) {
        const data = [...this.tripsData];
        data.forEach((el, i) => {
          arr.push(el.route[0]);
          if (!data[i + 1]) {
            const len = el.route.length;
            arr.push(el.route[len - 1]);
          }
        });
      }
      return arr;
    },
    followRoute() {
      let arr = [];
      if (this.followData.length > 0) {
        arr = this.followData.map((r) => [r.latitude, r.longitude]);
      }
      return arr;
    },

    bounds() {
      const arr =
        this.routeCoordinates.length > 0
          ? this.routeCoordinates
          : [this.center];
      return latLngBounds(arr);
    },
  },
  beforeDestroy() {
    this.$store.commit("routePlaning/SET_JOB_ROUTES", {});
  },
  mounted() {
    // this.tripPlayBackData();
  },
  methods: {
    // fullScreenToggle(value) {
    // },
    getMarker(v) {
      return `marker-${v}`;
    },
    getPolylineOptions(val) {
      const polylineOptions = {
        color: val == "job" ? "green" : "#FFA500",
        weight: 3,
        opacity: 0,
        smoothFactor: 1,
      };

      return polylineOptions;
    },
    getPolyDotsColor() {
      // const detail = this.tripsData.find(
      //   (r) => r.position.latitude == val[0] && r.position.longitude == val[1]
      // );
      // return detail.position.speed > 35 ? "red" : "green";
    },
    showPopupPolyline(event) {
      // Get the coordinates of the clicked point
      const latlng = event.latlng;
      this.popupLatLng = latlng;
    },
    showPopupMarkers(event) {
      // Get the coordinates of the clicked point
      const latlng = event.latlng;
      const detail = this.tripsData.find(
        (r) =>
          r.position.latitude == latlng.lat &&
          r.position.longitude == latlng.lng
      );
      // Set the popup coordinates and content
      this.popupLatLng = { ...latlng, ...detail };
    },
    //
    tripPlayBackData() {
      this.routeCoordinates = [];
      if (this.isPlaying) {
        if (this.count > 0) {
          for (let i = 0; i <= this.count; i++) {
            const r = this.tripsData[i];
            this.routeCoordinates.push([
              r.position.latitude,
              r.position.longitude,
            ]);
          }
        }
        // this.count = 0; // Initialize the index counter outside setInterval
        this.intervalId = setInterval(() => {
          const r = this.tripsData[this.count];
          if (r) {
            this.routeCoordinates.push([
              r.position.latitude,
              r.position.longitude,
            ]);
            this.count++;
            if (this.count === this.tripsData.length) {
              this.tripStop();
            }
          }
        }, this.intervalTime);
      } else {
        const arr = this.tripsData.map((r) => [
          r.position.latitude,
          r.position.longitude,
        ]);
        this.routeCoordinates = arr.filter((r) => r[0]);
      }
    },
    tripPlay() {
      this.isPlaying = true;
      this.tripPlayBackData();
    },
    tripPause() {
      this.isPlaying = false;
      clearInterval(this.intervalId); // Clear interval when all data points are processed
    },
    tripStop() {
      this.isPlaying = false;
      this.count = 1;
      clearInterval(this.intervalId); // Clear interval when all data points are processed
      this.tripPlayBackData();
    },
    setIntervalTime() {
      this.intervalTime -= 200;

      if (this.intervalTime <= 0) this.intervalTime = 1000;
    },
  },
};
</script>
<style lang="sass" scoped>
.VueLeafMap
  position: absolute !important
  width: 100% !important
  height: 100% !important
  top: 0
  left: 0
:deep .leaflet-marker-icon.leaflet-zoom-animated.leaflet-interactive
  display: flex
  justify-content: center


.gap-05
  gap: 0.5rem
</style>
